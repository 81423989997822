import React from "react";
import { createRoot } from "react-dom/client";
import App from "./components/App";
import Quotes from "./components/Quotes/Quotes";
import("./styles/custom.scss");
const gameRootContainer = document.getElementById("root");
const gameRoot = createRoot(gameRootContainer);
gameRoot.render(React.createElement(App, null));
const quotesRootContainer = document.getElementById("quotes");
const quotesRoot = createRoot(quotesRootContainer);
quotesRoot.render(React.createElement(Quotes, null));
