import React from "react";
import * as styles from "./Quotes.module.css";
const Quotes = () => {
    const quotes = [
        {
            text: "What's wrong with you?",
            source: "My Mom",
        },
        {
            text: "It's already difficult enough",
            source: "Also My Mom",
        },
        {
            text: "I'm in misery",
            source: "My Brother",
        },
        {
            text: "It's the hardest word game I've ever played",
            source: "My Coworker",
        },
        {
            text: "You misunderstood what I was saying…",
            source: "My Other Coworker",
        },
        {
            text: "This is evil",
            source: "My Other Other Coworker",
        },
        {
            text: "This used to be a lot harder",
            source: "Me",
        },
    ];
    return (React.createElement("div", { className: styles.quotes }, quotes.map((quote, i) => {
        return (React.createElement("div", { key: i, className: "text-center " + styles.quote },
            React.createElement("blockquote", { className: "blockquote" },
                React.createElement("em", null,
                    "\"",
                    quote.text,
                    "\"")),
            "- ",
            quote.source));
    })));
};
export default Quotes;
