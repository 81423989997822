import React from "react";
import KeyboardRow from "../KeyboardRow/KeyboardRow";
import * as styles from "./Keyboard.module.css";
const Keyboard = (props) => {
    const { keyboardState } = props;
    /**
     *
     * @param keyboard
     */
    function keyboardIntoRows(keyboard) {
        const output = [[]];
        let rowIndex = 0;
        for (const letter of keyboard) {
            if (letter.row !== rowIndex) {
                rowIndex++;
                output.push([]);
            }
            output[rowIndex].push(letter);
        }
        return output;
    }
    const letters = keyboardIntoRows(keyboardState);
    return (React.createElement("div", { className: styles.keyboard }, letters.map((row, rIndex) => (React.createElement(KeyboardRow, { key: rIndex, row: row, rIndex: rIndex, isBackspaceEnabled: props.isBackspaceEnabled, isEnterEnabled: props.isEnterEnabled, onBackClick: () => props.onBackClick(), onEnterClick: () => props.onEnterClick(), onKeyClick: (e) => props.onKeyClick(e) })))));
};
export default Keyboard;
