// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".rRP53y9nx0qTz_6O6_Dt {\n    border: 2px solid black;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 60px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/attempts/AttemptLetter/AttemptLetter.module.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;AAChB","sourcesContent":[".attemptLetter {\n    border: 2px solid black;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 60px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"attemptLetter": "rRP53y9nx0qTz_6O6_Dt"
};
export default ___CSS_LOADER_EXPORT___;
