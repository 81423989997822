"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.stringFromAttempt = exports.checkWordOfTheDay = exports.removeCorrectValues = exports.findIncludedCharacters = exports.findMatchingCharacters = exports.checkWord = void 0;
const types_1 = require("../types");
const dictionaryLoader_1 = require("./dictionary/dictionaryLoader");
function checkWord(attemptedWord, actualWord) {
    const cleanedAttemptedWord = attemptedWord.toUpperCase();
    const cleanedActualWord = actualWord.toUpperCase();
    return cleanedAttemptedWord === cleanedActualWord;
}
exports.checkWord = checkWord;
function findMatchingCharacters(attemptedWord, actualWord) {
    const output = [];
    const attemptArray = attemptedWord.toUpperCase().split('');
    const actualArray = actualWord.toUpperCase().split('');
    for (let x = 0; x < attemptArray.length; x++) {
        output.push(attemptArray[x] === actualArray[x]);
    }
    return output;
}
exports.findMatchingCharacters = findMatchingCharacters;
function findIncludedCharacters(attemptedWord, actualWord) {
    const output = [];
    const attemptArray = attemptedWord.toUpperCase().split('');
    const actualArray = actualWord.toUpperCase().split('');
    for (let x = 0; x < attemptArray.length; x++) {
        const matchIndex = actualArray.findIndex((l) => l === attemptArray[x]);
        if (matchIndex >= 0 && attemptArray[x] !== '_') {
            actualArray[matchIndex] = '';
            output.push(true);
        }
        else {
            output.push(false);
        }
    }
    return output;
}
exports.findIncludedCharacters = findIncludedCharacters;
function removeCorrectValues(attempt, lettersInCorrectPosition) {
    const wordAsArray = attempt.split('');
    let output = '';
    for (let x = 0; x < wordAsArray.length; x++) {
        output += lettersInCorrectPosition[x] ? '_' : wordAsArray[x];
    }
    return output;
}
exports.removeCorrectValues = removeCorrectValues;
function checkWordOfTheDay(attempt, override) {
    const actualWord = override !== null && override !== void 0 ? override : (0, dictionaryLoader_1.getWord)();
    const result = [];
    const lettersInCorrectPosition = findMatchingCharacters(attempt, actualWord);
    const attemptWithCorrectValuesRemoved = removeCorrectValues(attempt, lettersInCorrectPosition);
    const actualWordWithCorrectValuesRemoved = removeCorrectValues(actualWord, lettersInCorrectPosition);
    const lettersInWrongPosition = findIncludedCharacters(attemptWithCorrectValuesRemoved, actualWordWithCorrectValuesRemoved);
    for (let x = 0; x < attempt.length; x++) {
        let letterState;
        if (lettersInCorrectPosition[x]) {
            letterState = types_1.ELetterState.inPosition;
        }
        else if (lettersInWrongPosition[x]) {
            letterState = types_1.ELetterState.inWord;
        }
        else {
            letterState = types_1.ELetterState.notInWord;
        }
        result.push({ character: attempt[x], state: letterState });
    }
    return result;
}
exports.checkWordOfTheDay = checkWordOfTheDay;
function stringFromAttempt(attempt) {
    return attempt.reduce((newString, currentLetter) => newString + currentLetter.character, '');
}
exports.stringFromAttempt = stringFromAttempt;
