// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".hVf3HpFB8OqiA3WXUkKw {\n    margin: 0.5rem 0;\n}\n", "",{"version":3,"sources":["webpack://./src/components/attempts/CurrentAttempt/CurrentAttempt.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB","sourcesContent":[".word {\n    margin: 0.5rem 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"word": "hVf3HpFB8OqiA3WXUkKw"
};
export default ___CSS_LOADER_EXPORT___;
