"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isInDictionary = exports.getDictionary = exports.getWord = void 0;
const wordListRaw = require('./dict.json');
const wordListAlpha = require('./dictAlpha.json');
function getDaysSince() {
    const date1 = new Date('03/26/2022');
    const today = new Date();
    const differenceInTime = today.getTime() - date1.getTime();
    const differenceInDays = differenceInTime / (1000 * 3600 * 24);
    return Math.floor(differenceInDays);
}
function getWord() {
    return wordListRaw[getDaysSince()];
}
exports.getWord = getWord;
function getDictionary() {
    return wordListAlpha;
}
exports.getDictionary = getDictionary;
function isInDictionary(word) {
    return getDictionary().includes(word.toLowerCase());
}
exports.isInDictionary = isInDictionary;
