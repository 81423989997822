import React from "react";
import { createStartingKeyboard, isKeyOnKeyboard } from "../helpers/create-keyboard.";
import { isInDictionary } from "../helpers/dictionary/dictionaryLoader";
import { stringFromAttempt } from "../helpers/wordChecker";
import { ELetterState } from "../types";
import styles from "./App.module.css";
import CurrentAttempt from "./attempts/CurrentAttempt/CurrentAttempt";
import PreviousAttempts from "./attempts/PreviousAttempts/PreviousAttempts";
import Keyboard from "./keyboard/Keyboard/Keyboard";
/**
 *
 */
class App extends React.Component {
    /**
     *
     * @param props
     */
    constructor() {
        var _a;
        super(undefined);
        const stateFromStorageString = (_a = window === null || window === void 0 ? void 0 : window.localStorage) === null || _a === void 0 ? void 0 : _a.getItem("wordhecc");
        const currentUTCDate = new Date().getUTCDate();
        let stateToSet = {
            solved: false,
            keyboard: createStartingKeyboard(),
            attempt: [],
            previousAttempts: [],
            stateCreated: currentUTCDate,
            error: "",
        };
        if (stateFromStorageString) {
            const stateFromStorage = JSON.parse(stateFromStorageString);
            if ((stateFromStorage === null || stateFromStorage === void 0 ? void 0 : stateFromStorage.stateCreated) === currentUTCDate) {
                stateToSet = stateFromStorage;
            }
        }
        this.state = stateToSet;
        this.ref = React.createRef();
    }
    /**
     *
     * @param newState
     */
    updateState(newState) {
        this.setState(newState, () => this.saveStateToStorage());
    }
    /**
     *
     */
    saveStateToStorage() {
        const stateToSave = { ...this.state };
        if (window.localStorage) {
            window.localStorage.setItem("wordhecc", JSON.stringify(stateToSave));
        }
    }
    /**
     *
     */
    setSolved() {
        this.updateState({ solved: true });
    }
    /**
     *
     * @param attempt
     */
    setAttempt(attempt) {
        this.updateState({ attempt });
    }
    /**
     *
     * @param response
     */
    updateKeys(response) {
        const { keyboard } = this.state;
        const keys = [...keyboard];
        const { result } = response;
        const updateKeyState = (key) => {
            const match = result.find((char) => char.character === key.character);
            if (!match)
                return;
            if (match.state > key.state) {
                key.state = match.state;
            }
        };
        for (const key of keys) {
            updateKeyState(key);
        }
        this.updateState({ keyboard: keys });
    }
    /**
     *
     * @param letter
     */
    addLetterToAttempt(letter) {
        const { attempt } = this.state;
        const items = [...attempt];
        if (items.length >= 8)
            return;
        items.push({ character: letter.character, state: ELetterState.unused });
        this.setAttempt(items);
    }
    /**
     *
     */
    removeLetterFromAttempt() {
        const { attempt } = this.state;
        const items = [...attempt];
        items.pop();
        this.setAttempt(items);
    }
    /**
     *
     */
    async submitAttempt() {
        const { attempt, previousAttempts } = this.state;
        const attemptAsString = stringFromAttempt(attempt);
        const previousAttemptsStrings = previousAttempts.length ? previousAttempts.map((a) => stringFromAttempt(a)) : [];
        const result = await fetch("/.netlify/functions/check", {
            method: "POST",
            body: JSON.stringify({
                attempt: attemptAsString,
                count: previousAttemptsStrings.length + 1,
                previousAttempts: previousAttemptsStrings,
            }),
        }).catch((err) => {
            this.setState({ error: "A connection error occurred " + err.code });
        });
        if (!result || result.status !== 200) {
            this.setState({
                error: `A connection error occurred (${result === null || result === void 0 ? void 0 : result.status})`,
            });
            return;
        }
        const response = await result.json();
        if (response.error) {
            return;
        }
        this.updateAttempts(response);
        this.updateKeys(response);
        this.setState({ error: "" });
    }
    /**
     *
     * @param response
     */
    updateAttempts(response) {
        if (response.complete)
            this.setSolved();
        const { previousAttempts } = this.state;
        const oldPreviousAttempts = [...previousAttempts];
        oldPreviousAttempts.push(response.result);
        this.updateState({
            attempt: [],
            previousAttempts: oldPreviousAttempts,
        });
    }
    /**
     *
     * @param e
     */
    handleKeypress(e) {
        if (this.state.solved)
            return;
        const { key } = e;
        if (key === "Backspace") {
            this.removeLetterFromAttempt();
            return;
        }
        if (key === "Enter" && isInDictionary(stringFromAttempt(this.state.attempt))) {
            this.submitAttempt();
            return;
        }
        if (!isKeyOnKeyboard(key))
            return;
        this.addLetterToAttempt({
            character: key.toUpperCase(),
            state: ELetterState.unused,
            row: 0,
        });
    }
    /**
     *
     */
    componentDidMount() {
        window.addEventListener("keyup", (e) => this.handleKeypress(e));
    }
    /**
     *
     */
    componentWillUnmount() {
        window.removeEventListener("keyup", this.handleKeypress);
    }
    /**
     *
     */
    getSolvedText() {
        const { previousAttempts } = this.state;
        return (React.createElement("div", null,
            React.createElement("h1", { className: styles.congratulations }, "You did it!"),
            React.createElement("p", { className: "text-center" },
                "And it only took you ",
                previousAttempts.length,
                " tries!")));
    }
    /**
     *
     */
    getKeyboard() {
        const { attempt, keyboard } = this.state;
        return (React.createElement(Keyboard, { keyboardState: keyboard, onKeyClick: (letter) => this.addLetterToAttempt(letter), onBackClick: () => this.removeLetterFromAttempt(), onEnterClick: () => this.submitAttempt(), isEnterEnabled: isInDictionary(stringFromAttempt(attempt)), isBackspaceEnabled: attempt.length > 0 }));
    }
    /**
     *
     */
    render() {
        const { solved, attempt, previousAttempts, error } = this.state;
        return (React.createElement("div", { className: styles.container, ref: this.ref },
            React.createElement(PreviousAttempts, { previousAttempts: previousAttempts }),
            React.createElement("div", null, error),
            solved ? (this.getSolvedText()) : (React.createElement("div", null,
                React.createElement(CurrentAttempt, { attempt: attempt }),
                " ",
                this.getKeyboard()))));
    }
}
export default App;
