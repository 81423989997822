"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createStartingKeyboard = exports.isKeyOnKeyboard = void 0;
const types_1 = require("../types");
const letters = [
    ['Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P'],
    ['A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L'],
    ['Z', 'X', 'C', 'V', 'B', 'N', 'M'],
];
function isKeyOnKeyboard(key) {
    return letters.flat(1).includes(key.toUpperCase());
}
exports.isKeyOnKeyboard = isKeyOnKeyboard;
function createStartingKeyboard() {
    const rows = letters.map((row, rIndex) => {
        return row.map((letter) => {
            return {
                character: letter,
                row: rIndex,
                state: types_1.ELetterState.unused,
            };
        });
    });
    return rows.flat(1);
}
exports.createStartingKeyboard = createStartingKeyboard;
