"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.stateClasses = exports.ELetterState = void 0;
/**
* Colour states for letters.
**/
var ELetterState;
(function (ELetterState) {
    ELetterState[ELetterState["unused"] = 0] = "unused";
    ELetterState[ELetterState["notInWord"] = 1] = "notInWord";
    ELetterState[ELetterState["inWord"] = 2] = "inWord";
    ELetterState[ELetterState["inPosition"] = 3] = "inPosition";
})(ELetterState || (exports.ELetterState = ELetterState = {}));
exports.stateClasses = {
    [ELetterState.unused]: 'unused',
    [ELetterState.notInWord]: 'notInWord',
    [ELetterState.inPosition]: 'inPosition',
    [ELetterState.inWord]: 'inWord',
};
