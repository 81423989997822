// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".V_fybiGt1qGBQroh52pT {\n  max-width: 500px;\n  margin: 1rem auto;\n}\n\n.UA2nPVb2CxLBUt3R3bn6 {\n  text-align: center;\n}\n", "",{"version":3,"sources":["webpack://./src/components/App.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".container {\n  max-width: 500px;\n  margin: 1rem auto;\n}\n\n.congratulations {\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
export var container = "V_fybiGt1qGBQroh52pT";
export var congratulations = "UA2nPVb2CxLBUt3R3bn6";
export default ___CSS_LOADER_EXPORT___;
