import { ELetterState } from "../types";
const letters = [
    ["Q", "W", "E", "R", "T", "Y", "U", "I", "O", "P"],
    ["A", "S", "D", "F", "G", "H", "J", "K", "L"],
    ["Z", "X", "C", "V", "B", "N", "M"],
];
/**
 *
 * @param key
 */
export function isKeyOnKeyboard(key) {
    return letters.flat(1).includes(key.toUpperCase());
}
/**
 *
 */
export function createStartingKeyboard() {
    const rows = letters.map((row, rIndex) => {
        return row.map((letter) => {
            return {
                character: letter,
                row: rIndex,
                state: ELetterState.unused,
            };
        });
    });
    return rows.flat(1);
}
