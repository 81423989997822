/**
 * Colour states for letters.
 */
export var ELetterState;
(function (ELetterState) {
    ELetterState[ELetterState["unused"] = 0] = "unused";
    ELetterState[ELetterState["notInWord"] = 1] = "notInWord";
    ELetterState[ELetterState["inWord"] = 2] = "inWord";
    ELetterState[ELetterState["inPosition"] = 3] = "inPosition";
})(ELetterState || (ELetterState = {}));
export const stateClasses = {
    [ELetterState.unused]: "unused",
    [ELetterState.notInWord]: "notInWord",
    [ELetterState.inPosition]: "inPosition",
    [ELetterState.inWord]: "inWord",
};
