// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._17QkBtvBY417az1jVsmX {\n  display: flex;\n  flex-wrap: wrap;\n  gap: 2rem;\n  justify-content: center;\n  margin: 2rem;\n}\n\n.QajfkwdkuZrLD9sP8iFQ {\n}\n", "",{"version":3,"sources":["webpack://./src/components/Quotes/Quotes.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,eAAe;EACf,SAAS;EACT,uBAAuB;EACvB,YAAY;AACd;;AAEA;AACA","sourcesContent":[".quotes {\n  display: flex;\n  flex-wrap: wrap;\n  gap: 2rem;\n  justify-content: center;\n  margin: 2rem;\n}\n\n.quote {\n}\n"],"sourceRoot":""}]);
// Exports
export var quotes = "_17QkBtvBY417az1jVsmX";
export var quote = "QajfkwdkuZrLD9sP8iFQ";
export default ___CSS_LOADER_EXPORT___;
