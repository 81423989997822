// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".touadDGFS31WSusx9XYv {\n    display: grid;\n   grid-template-columns: repeat(8, 1fr);\n    gap: 0.25rem;\n    border: 1px solid black;\n    padding: 0.5rem;\n}\n", "",{"version":3,"sources":["webpack://./src/components/attempts/Attempt/Attempt.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;GACd,qCAAqC;IACpC,YAAY;IACZ,uBAAuB;IACvB,eAAe;AACnB","sourcesContent":[".attempt {\n    display: grid;\n   grid-template-columns: repeat(8, 1fr);\n    gap: 0.25rem;\n    border: 1px solid black;\n    padding: 0.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"attempt": "touadDGFS31WSusx9XYv"
};
export default ___CSS_LOADER_EXPORT___;
