import React from "react";
import ActionButton from "../ActiobButton/ActionButton";
import Key from "../Key/Key";
import Spacer from "../Spacer/Spacer";
import * as styles from "./KeyboardRow.module.css";
const KeyboardRow = (props) => {
    const { row, rIndex, onKeyClick, onBackClick, onEnterClick, isBackspaceEnabled, isEnterEnabled } = props;
    const rowTemplates = ["repeat(10, 1fr)", "0.5fr repeat(9, 1fr) 0.5fr", "1.5fr repeat(7, 1fr) 1.5fr"];
    const rowStyles = { gridTemplateColumns: rowTemplates[rIndex] };
    return (React.createElement("div", { key: rIndex, className: styles.row, style: rowStyles },
        rIndex === 2 ? React.createElement(ActionButton, { onClick: onEnterClick, enabled: isEnterEnabled, label: "Enter" }) : "",
        rIndex === 1 ? React.createElement(Spacer, null) : "",
        row.map((letter) => (React.createElement(Key, { key: letter.character, letter: letter, onClick: () => onKeyClick(letter) }))),
        rIndex === 1 ? React.createElement(Spacer, null) : "",
        rIndex === 2 ? React.createElement(ActionButton, { onClick: onBackClick, enabled: isBackspaceEnabled, label: "Backspace" }) : ""));
};
export default KeyboardRow;
